<script>
  //export let language = "de";
  import LinkedIn from '../icons/LinkedIn.svelte';
  import Facebook from '../icons/Facebook.svelte';
  import X from '../icons/X.svelte';
  import Image from './Image.svelte';
  import { getContext } from 'svelte';
  import i18n_dictionary from '../../config/i18n.js';

  export let id;
  export let email;
  export let position;
  export let firstName;
  export let lastName;
  export let telephone;
  export let fax;
  export let address;
  export let room;
  export let researchAreas;
  export let image;
  export let socialMedia = undefined;
  export let title = '';

  const i18n = key => {
    return i18n_dictionary[key][getContext('language')];
  };
</script>

<div id="main-container">
  <Image
    src={image}
    alt={`${i18n('portraitOf')} ${firstName} ${lastName}`}
    placeHolderAlt={`${i18n('placeholderFor')}: ${i18n(
      'portraitOf'
    )} ${firstName} ${lastName}`}
    reroute={{
      route: 'https://fis.tu-chemnitz.de/vivo/',
      rerouteTo: 'https://vsr.informatik.tu-chemnitz.de/tucfisWebComponents/'
    }}
  />
  <!-- <hr /> -->
  <div id="text-container">
    <div id="text-title">
      {#if id}
        <a href={id}>
          <h1>{`${title} ${firstName} ${lastName}`}</h1>
        </a>
      {:else}
        <h1>{`${title} ${firstName} ${lastName}`}</h1>
      {/if}
      {#if position}
        <h2>{position}</h2>
      {/if}
    </div>
    <ul>
      <li>
        <span>{i18n('email')}: </span>
        <span><a href={`mailto:${email}`}>{email}</a></span>
      </li>
      {#if address}
        <li>
          <span>{i18n('address')}: </span>
          <span>{address}</span>
        </li>
      {/if}
      {#if room}
        <li>
          <span>{i18n('room')}: </span>
          <span>{room}</span>
        </li>
      {/if}
      {#if researchAreas}
        <li>
          <span>{i18n('researchAreas')}: </span>
          <span>{researchAreas}</span>
        </li>
      {/if}
      {#if telephone}
        <li>
          <span>{i18n('telephone')}: </span>
          <span
            ><a href="tel:{telephone}">
              {telephone}
            </a></span
          >
        </li>
      {/if}
      {#if fax}
        <li>
          <span>{i18n('fax')}: </span>
          <span>{fax}</span>
        </li>
      {/if}
    </ul>
    {#if socialMedia}
      <div id="social-icons-container">
        <a href={socialMedia.linkedIn} target="_blank">
          <LinkedIn />
        </a>
        <a href={socialMedia.facebook} target="_blank">
          <Facebook />
        </a>
        <a href={socialMedia.xing} target="_blank">
          <X />
        </a>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  #main-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin: 0;
    padding: 16px 20px;
    gap: 30px;
    //border: 1px solid #0dc9e6;
    
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px 10px;
      gap: 20px;
    }
  }

  #text-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    //flex-grow: 1;
    //boder: 1px solid #ccc;
  }

  #text-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3px;
  }

  #text-title h1 {
    font-size: calc(var(--font-size) * 1.07);
    text-transform: uppercase;
    margin: 0;
    color: var(--primary-color);
  }

  #text-title h2 {
    font-size: calc(var(--font-size) * 1.07);
    font-weight: normal;
    font-style: italic;
    margin: 0;
    color: #777;
    text-transform: uppercase;
  }

  #text-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #text-container li {
    margin: 5px 0;
  }

  #text-container li span:first-child {
    font-weight: bold;
  }

  #text-container a {
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: none;
  }

  #text-container a:hover {
    text-decoration: underline;
  }

  #social-icons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    //margin: 12px 0;
    margin-left: 14px;
    gap: 8px;
  }

  #social-icons-container > a {
    width: 35px;
    //margin: 0 8px;
    color: #777;
  }

  #social-icons-container > a:hover {
    color: var(--primary-color);
  }
</style>