<script>
  import { z } from 'zod';

  import TucfisPeopleConfig from '../../config/web-components/TucfisPeopleConfig.js';
  import { createParseAndValidateAttributesStore } from '../../utils/stores/parseAndValidateAttributesStore.js';
  import {
    parsePeopleAddress,
    parsePeoplePosition,
    parsePeopleRoom,
    parsePeopleResearchAreas,
    parseTitle,
    parsePeopleMainWebsite
  } from '../../utils/parsers.js';
  import { DataTransformer } from '../../utils/transformer.js';

  import { getListData } from '../../services/accessControl.js';

  import People from './People.svelte';
  import ShowError from '../ShowError.svelte';
  import PeopleSkeleton from '../shared/LoadingSkeletonRound.svelte';
  import FilterBar from '../filterbar/FilterBar.svelte';

  export let orgUnit;
  export let context;
  export let sortBy;
  export let sort;
  export let limitTo;
  export let filterValue;
  export let filterBy;
  export let filterOperation;
  export let showFilterBar;
  export let hideStudentPhoneFax;

  /* This urls is used as a temporary solution to attach urls to the people data
  Remove when urls are attached to the people data */
  export let urls;

  const {
    attributes,
    state: attributesState,
    parseAndValidate: parseAttributes
  } = createParseAndValidateAttributesStore();

  $: parseAttributes({
    context: {
      variable: context,
      validationSchema: z.string().min(1)
    },
    orgUnit: {
      variable: orgUnit,
      displayName: 'org-unit',
      validationSchema: z.string().min(1)
    },
    sortBy: {
      variable: sortBy,
      displayName: 'sort-by',
      validationSchema: z
        .enum(Object.keys(TucfisPeopleConfig.allowedSortByVariablesAttrToProp))
        .optional()
        .transform(
          value => TucfisPeopleConfig.allowedSortByVariablesAttrToProp[value]
        )
    },
    filterBy: {
      variable: filterBy,
      displayName: 'filter-by',
      validationSchema: z
        .enum(
          Object.keys(TucfisPeopleConfig.allowedFilterByVariablesAttrToProp)
        )
        .optional()
        .transform(
          value => TucfisPeopleConfig.allowedFilterByVariablesAttrToProp[value]
        )
    }
  });

  let clientTransformer;

  $: {
    clientTransformer = new DataTransformer();
    // Potentially remove Telephone & Fax if student assistant
    if (hideStudentPhoneFax)
    {
      clientTransformer = clientTransformer.customTransformation(items => {
        items.forEach(item => {
          if (item.positions?.[0]?.label.toLowerCase().includes('student')) {
            item.telephone = "";
            item.fax = "";
            item.phone = "";
          }
        });
        return items;
      });
    }

    switch ($attributes.sortBy) {
      case 'positions':
        clientTransformer = clientTransformer.sortStringByRank(
          sort,
          item => item.positions?.[0]?.label,
          {
            professor: 1,
            dekan: 1,
            rektor: 1,
            secretary: 2,
            sekretärin: 2,
            'senior researcher': 5,
            researcher: 10,
            technician: 15,
            'student assistant': 20
          }
        );
        break;
      case 'researchAreas':
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item.researchAreas?.[0]?.label;
        });
        break;
      case 'locatedInRoomOld':
      case 'locatedInRoom':
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy]?.[0];
        });
        break;
      default:
        clientTransformer = clientTransformer.sortString(sort, item => {
          return item[$attributes.sortBy];
        });
        break;
    }

    if (limitTo) {
      clientTransformer = clientTransformer.limitResults(limitTo);
    }
  }

  const updateSort = value => {
    sort = value;
  };

  const updateSortBy = value => {
    sortBy = value;
  };

  const updateLimitTo = value => {
    limitTo = value;
  };
</script>

{#if $attributesState.isValid}
  {#await getListData( 'people', { orgUnit, filterConfig: { filterBy: $attributes.filterBy, filterValue, filterOperation } } )}
    <div class="list-container">
      {#each new Array(5).fill(true) as _}
        <hr />
        <PeopleSkeleton />
      {/each}
    </div>
  {:then data}
    {#if showFilterBar}
      <FilterBar
        {updateSortBy}
        {updateSort}
        {sort}
        {sortBy}
        {limitTo}
        {updateLimitTo}
        showVariables={Object.keys(
          TucfisPeopleConfig.allowedSortByVariablesAttrToProp
        )}
      />
    {/if}
    <div class="list-container">
      {#each clientTransformer.transform(data) as people}
        <hr />
        <People
          id={parsePeopleMainWebsite({
            websites: people.websites,
            customUrls: urls,
            originalUrl: people.id
          })}
          firstName={people.firstName}
          title={parseTitle(people.positions)}
          lastName={people.lastName}
          email={people.primaryEmail}
          telephone={people.phone}
          fax={people.fax}
          position={parsePeoplePosition(people.positions, $attributes.context)}
          address={parsePeopleAddress({
            streetAddress: people.streetAddress,
            postalCode: people.postalCode,
            locality: people.locality
          })}
          room={parsePeopleRoom({
            oldRoom: people.locatedInRoomOld,
            newRoom: people.locatedInRoom
          })}
          image={people.thumbnail}
          researchAreas={parsePeopleResearchAreas(people.researchAreas)}
        />
      {/each}
    </div>
  {:catch e}
    <ShowError message={'Error when loading data: ' + e.toString()} />
  {/await}
{:else}
  <ShowError message={$attributesState.errors} />
{/if}

<style lang="scss">
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 14px 0;
    max-width: 100%;
    overflow-x: hidden;
  }

  .list-container hr:first-child {
    display: none;
  }
</style>
